import store from '@/store';
import _ from 'lodash';
import { i18n } from '@/plugins/i18n';
import { ref, watch } from '@vue/composition-api';

export default function useTripsList() {
    const tripListTable = ref([]);

    const tripsTableColumns = [
        { text: i18n.t('Trip'), value: 'short_name', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('Route'), value: 'route.long_name', class: 'text-uppercase', width: '10rem' },
        {
            text: i18n.t('WheelchairAcessible'),
            value: 'wheelchair_accessible',
            class: 'text-uppercase',
            width: '12rem',
        },

        {
            text: '',
            value: 'actions',
            width: '5%',
        },
    ];

    const searchQuery = ref('');
    const loading = ref(false);
    const selectedRows = ref([]);
    const wheelchairFilter = ref(null);
    const totalTripListTable = ref(0);
    const oldSearch = ref('');
    const oldWheelchairFilter = ref('');
    const options = ref({
        sortBy: ['id'],
        sortDesc: [true],
    });

    const fetchTripList = () => {
        let verifyQuery = store.getters['app-routes/getTripsQuery'];
        let isDeleteTrips = store.getters['app-routes/getIsDeleteTrip'];
        store
            .dispatch('app-routes/fetchTripList', {})
            .then((response) => {
                if (isDeleteTrips) {
                    tripListTable.value = [];
                    tripListTable.value.push(...response.data.data);
                    tripListTable.value = _.uniqBy(tripListTable.value, 'id');
                    store.commit[('app-routes/setIsDeleteTrip', false)];
                } else if (
                    oldSearch.value == verifyQuery.filters.$or[0].short_name.$containsi &&
                    oldWheelchairFilter.value == verifyQuery.filters.wheelchair_accessible.$containsi
                ) {
                    tripListTable.value = [];
                    tripListTable.value.push(...response.data.data);
                } else {
                    oldSearch.value = verifyQuery.filters.$or[0].short_name.$containsi;
                    oldWheelchairFilter.value = verifyQuery.filters.wheelchair_accessible.$containsi;
                    tripListTable.value = [];
                    tripListTable.value.push(...response.data.data);
                }

                totalTripListTable.value = response.data.meta.pagination.total;
                // parkTotalLocal.value = parkTotal;1

                // remove loading state
                loading.value = false;
            })
            .catch((error) => {
                throw error;
            });
    };

    watch([searchQuery, wheelchairFilter, options], () => {
        loading.value = true;
        selectedRows.value = [];
        fetchTripList();
    });

    return {
        tripsTableColumns,
        tripListTable,
        totalTripListTable,
        loading,
        selectedRows,
        searchQuery,
        wheelchairFilter,
        options,
        fetchTripList,
    };
}
